<template>
  <el-dialog
    title="角色"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="名称" prop="rName">
        <el-input v-model="temp.rName"></el-input>
      </el-form-item>
      <el-form-item :label="pit.mTitle" v-for="pit in auth_list" :key="pit.id">
        <el-checkbox @change="chk_all(pit.id)" v-model="pit['ay_full_chk']"
          >全选</el-checkbox
        >
        <el-checkbox-group
          v-model="pit.ay_chk"
          @change="chk_items($event, pit.id)"
        >
          <el-checkbox
            :label="it.mTitle"
            v-for="it in pit.children"
            :key="it.id"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    row_type(val) {
      Object.assign(this.temp, val);
    },
    show(val, oVal) {
      if (val != oVal) {
        this.dialogVisible = val;
      }
      this.getList();
    },
  },
  data() {
    return {
      auth_list: [],
      chk_all_checked: {},
      dialogVisible: false,
      temp: {
        id: "",
        rAddAdminID: "",
        rName: "",
        rIs_del: "",
      },
      rules: {
        rName: [
          {
            required: true,
            message: "名称必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    getList() {
      this.$api.sys_auth_get_tree({ rid: this.temp.id }).then((res) => {
        this.auth_list = res.data.data;
      });
    },
    chk_items(e, id) {
      // console.log(e, id);
      this.auth_list.forEach((item) => {
        if (item.id == id) {
          console.log(e.length, item.children.length);
          if (e.length == item.children.length) {
            item.ay_full_chk = true;
          } else {
            item.ay_full_chk = false;
          }
        }
      });
    },
    chk_all(id) {
      // let key = id;
      this.auth_list.forEach((item) => {
        if (item.id == id) {
          if (item.ay_full_chk && item.id == id) {
            item.ay_chk = [];
            item.children.forEach((it) => {
              item.ay_chk.push(it.mTitle);
            });
          } else if (!item.ay_full_chk && item.id == id) {
            item.ay_chk = [];
          }
        }
      });
    },
    handle_auth() {
      let ay = [];
      this.auth_list.forEach((item) => {
        if (item.ay_chk.length > 0) {
          ay.push(item.id);
          item.children.forEach((it) => {
            if (item.ay_chk.includes(it.mTitle)) ay.push(it.id);
          });
        }
      });
      return ay;
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$emit("close");
    },
    save() {
      // console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.temp.chk_ay = this.handle_auth();
          this.$api.roles_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
